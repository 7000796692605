import { default as cookies909di7xp6qMeta } from "/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/cookies.vue?macro=true";
import { default as cookiesSJcuRo4QFJMeta } from "/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/cookies.vue?macro=true";
import { default as indexIWI9dxk0SuMeta } from "/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/index.vue?macro=true";
import { default as legalFxL9desFKZMeta } from "/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/legal.vue?macro=true";
import { default as portfolio_45aboutouQYzJun6KMeta } from "/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-about.vue?macro=true";
import { default as portfolio_45feescRS98KDnNhMeta } from "/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-fees.vue?macro=true";
import { default as portfolio_45investorsyyTygkPe9oMeta } from "/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-investors.vue?macro=true";
import { default as portfolio_45issuersAX1tmWHkyXMeta } from "/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-issuers.vue?macro=true";
import { default as portfolio_45members4VNqIYMrgFMeta } from "/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-members.vue?macro=true";
import { default as indexeazX02ACTKMeta } from "/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/index.vue?macro=true";
import { default as transformation_45of_45an_45edav_45into_45a_45reitKdevT4obKjMeta } from "/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/transformation-of-an-edav-into-a-reit.vue?macro=true";
import { default as portfolio_45partnersOxxub1YFjaMeta } from "/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-partners.vue?macro=true";
import { default as privacypubaDS9jgeMeta } from "/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/privacy.vue?macro=true";
import { default as index8SUgJWL2VXMeta } from "/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/index.vue?macro=true";
import { default as legalDHPW7EpInZMeta } from "/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/legal.vue?macro=true";
import { default as portfolio_45aboutqhJTD9TQWFMeta } from "/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-about.vue?macro=true";
import { default as portfolio_45feeshPF5kYvnthMeta } from "/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-fees.vue?macro=true";
import { default as portfolio_45investorscdX67harajMeta } from "/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-investors.vue?macro=true";
import { default as portfolio_45issuers5zmTFA5BqyMeta } from "/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-issuers.vue?macro=true";
import { default as portfolio_45membersnUH5Lmg9kKMeta } from "/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-members.vue?macro=true";
import { default as indexYt43e36PVmMeta } from "/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/index.vue?macro=true";
import { default as transformation_45of_45an_45edav_45into_45a_45reitx202jh6zfHMeta } from "/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/transformation-of-an-edav-into-a-reit.vue?macro=true";
import { default as portfolio_45partnersvycwePvHVoMeta } from "/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-partners.vue?macro=true";
import { default as privacymQAqqqyv1EMeta } from "/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/privacy.vue?macro=true";
export default [
  {
    name: cookies909di7xp6qMeta?.name ?? "cookies",
    path: cookies909di7xp6qMeta?.path ?? "/cookies",
    meta: cookies909di7xp6qMeta || {},
    alias: cookies909di7xp6qMeta?.alias || [],
    redirect: cookies909di7xp6qMeta?.redirect,
    component: () => import("/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookiesSJcuRo4QFJMeta?.name ?? "es-cookies",
    path: cookiesSJcuRo4QFJMeta?.path ?? "/es/cookies",
    meta: cookiesSJcuRo4QFJMeta || {},
    alias: cookiesSJcuRo4QFJMeta?.alias || [],
    redirect: cookiesSJcuRo4QFJMeta?.redirect,
    component: () => import("/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/cookies.vue").then(m => m.default || m)
  },
  {
    name: indexIWI9dxk0SuMeta?.name ?? "es",
    path: indexIWI9dxk0SuMeta?.path ?? "/es",
    meta: indexIWI9dxk0SuMeta || {},
    alias: indexIWI9dxk0SuMeta?.alias || [],
    redirect: indexIWI9dxk0SuMeta?.redirect,
    component: () => import("/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/index.vue").then(m => m.default || m)
  },
  {
    name: legalFxL9desFKZMeta?.name ?? "es-legal",
    path: legalFxL9desFKZMeta?.path ?? "/es/legal",
    meta: legalFxL9desFKZMeta || {},
    alias: legalFxL9desFKZMeta?.alias || [],
    redirect: legalFxL9desFKZMeta?.redirect,
    component: () => import("/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/legal.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45aboutouQYzJun6KMeta?.name ?? "es-portfolio-about",
    path: portfolio_45aboutouQYzJun6KMeta?.path ?? "/es/portfolio-about",
    meta: portfolio_45aboutouQYzJun6KMeta || {},
    alias: portfolio_45aboutouQYzJun6KMeta?.alias || [],
    redirect: portfolio_45aboutouQYzJun6KMeta?.redirect,
    component: () => import("/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-about.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45feescRS98KDnNhMeta?.name ?? "es-portfolio-fees",
    path: portfolio_45feescRS98KDnNhMeta?.path ?? "/es/portfolio-fees",
    meta: portfolio_45feescRS98KDnNhMeta || {},
    alias: portfolio_45feescRS98KDnNhMeta?.alias || [],
    redirect: portfolio_45feescRS98KDnNhMeta?.redirect,
    component: () => import("/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-fees.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45investorsyyTygkPe9oMeta?.name ?? "es-portfolio-investors",
    path: portfolio_45investorsyyTygkPe9oMeta?.path ?? "/es/portfolio-investors",
    meta: portfolio_45investorsyyTygkPe9oMeta || {},
    alias: portfolio_45investorsyyTygkPe9oMeta?.alias || [],
    redirect: portfolio_45investorsyyTygkPe9oMeta?.redirect,
    component: () => import("/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-investors.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45issuersAX1tmWHkyXMeta?.name ?? "es-portfolio-issuers",
    path: portfolio_45issuersAX1tmWHkyXMeta?.path ?? "/es/portfolio-issuers",
    meta: portfolio_45issuersAX1tmWHkyXMeta || {},
    alias: portfolio_45issuersAX1tmWHkyXMeta?.alias || [],
    redirect: portfolio_45issuersAX1tmWHkyXMeta?.redirect,
    component: () => import("/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-issuers.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45members4VNqIYMrgFMeta?.name ?? "es-portfolio-members",
    path: portfolio_45members4VNqIYMrgFMeta?.path ?? "/es/portfolio-members",
    meta: portfolio_45members4VNqIYMrgFMeta || {},
    alias: portfolio_45members4VNqIYMrgFMeta?.alias || [],
    redirect: portfolio_45members4VNqIYMrgFMeta?.redirect,
    component: () => import("/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-members.vue").then(m => m.default || m)
  },
  {
    name: indexeazX02ACTKMeta?.name ?? "es-portfolio-news",
    path: indexeazX02ACTKMeta?.path ?? "/es/portfolio-news",
    meta: indexeazX02ACTKMeta || {},
    alias: indexeazX02ACTKMeta?.alias || [],
    redirect: indexeazX02ACTKMeta?.redirect,
    component: () => import("/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/index.vue").then(m => m.default || m)
  },
  {
    name: transformation_45of_45an_45edav_45into_45a_45reitKdevT4obKjMeta?.name ?? "es-portfolio-news-transformation-of-an-edav-into-a-reit",
    path: transformation_45of_45an_45edav_45into_45a_45reitKdevT4obKjMeta?.path ?? "/es/portfolio-news/transformation-of-an-edav-into-a-reit",
    meta: transformation_45of_45an_45edav_45into_45a_45reitKdevT4obKjMeta || {},
    alias: transformation_45of_45an_45edav_45into_45a_45reitKdevT4obKjMeta?.alias || [],
    redirect: transformation_45of_45an_45edav_45into_45a_45reitKdevT4obKjMeta?.redirect,
    component: () => import("/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/transformation-of-an-edav-into-a-reit.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45partnersOxxub1YFjaMeta?.name ?? "es-portfolio-partners",
    path: portfolio_45partnersOxxub1YFjaMeta?.path ?? "/es/portfolio-partners",
    meta: portfolio_45partnersOxxub1YFjaMeta || {},
    alias: portfolio_45partnersOxxub1YFjaMeta?.alias || [],
    redirect: portfolio_45partnersOxxub1YFjaMeta?.redirect,
    component: () => import("/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-partners.vue").then(m => m.default || m)
  },
  {
    name: privacypubaDS9jgeMeta?.name ?? "es-privacy",
    path: privacypubaDS9jgeMeta?.path ?? "/es/privacy",
    meta: privacypubaDS9jgeMeta || {},
    alias: privacypubaDS9jgeMeta?.alias || [],
    redirect: privacypubaDS9jgeMeta?.redirect,
    component: () => import("/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/privacy.vue").then(m => m.default || m)
  },
  {
    name: index8SUgJWL2VXMeta?.name ?? "index",
    path: index8SUgJWL2VXMeta?.path ?? "/",
    meta: index8SUgJWL2VXMeta || {},
    alias: index8SUgJWL2VXMeta?.alias || [],
    redirect: index8SUgJWL2VXMeta?.redirect,
    component: () => import("/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: legalDHPW7EpInZMeta?.name ?? "legal",
    path: legalDHPW7EpInZMeta?.path ?? "/legal",
    meta: legalDHPW7EpInZMeta || {},
    alias: legalDHPW7EpInZMeta?.alias || [],
    redirect: legalDHPW7EpInZMeta?.redirect,
    component: () => import("/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45aboutqhJTD9TQWFMeta?.name ?? "portfolio-about",
    path: portfolio_45aboutqhJTD9TQWFMeta?.path ?? "/portfolio-about",
    meta: portfolio_45aboutqhJTD9TQWFMeta || {},
    alias: portfolio_45aboutqhJTD9TQWFMeta?.alias || [],
    redirect: portfolio_45aboutqhJTD9TQWFMeta?.redirect,
    component: () => import("/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-about.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45feeshPF5kYvnthMeta?.name ?? "portfolio-fees",
    path: portfolio_45feeshPF5kYvnthMeta?.path ?? "/portfolio-fees",
    meta: portfolio_45feeshPF5kYvnthMeta || {},
    alias: portfolio_45feeshPF5kYvnthMeta?.alias || [],
    redirect: portfolio_45feeshPF5kYvnthMeta?.redirect,
    component: () => import("/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-fees.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45investorscdX67harajMeta?.name ?? "portfolio-investors",
    path: portfolio_45investorscdX67harajMeta?.path ?? "/portfolio-investors",
    meta: portfolio_45investorscdX67harajMeta || {},
    alias: portfolio_45investorscdX67harajMeta?.alias || [],
    redirect: portfolio_45investorscdX67harajMeta?.redirect,
    component: () => import("/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-investors.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45issuers5zmTFA5BqyMeta?.name ?? "portfolio-issuers",
    path: portfolio_45issuers5zmTFA5BqyMeta?.path ?? "/portfolio-issuers",
    meta: portfolio_45issuers5zmTFA5BqyMeta || {},
    alias: portfolio_45issuers5zmTFA5BqyMeta?.alias || [],
    redirect: portfolio_45issuers5zmTFA5BqyMeta?.redirect,
    component: () => import("/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-issuers.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45membersnUH5Lmg9kKMeta?.name ?? "portfolio-members",
    path: portfolio_45membersnUH5Lmg9kKMeta?.path ?? "/portfolio-members",
    meta: portfolio_45membersnUH5Lmg9kKMeta || {},
    alias: portfolio_45membersnUH5Lmg9kKMeta?.alias || [],
    redirect: portfolio_45membersnUH5Lmg9kKMeta?.redirect,
    component: () => import("/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-members.vue").then(m => m.default || m)
  },
  {
    name: indexYt43e36PVmMeta?.name ?? "portfolio-news",
    path: indexYt43e36PVmMeta?.path ?? "/portfolio-news",
    meta: indexYt43e36PVmMeta || {},
    alias: indexYt43e36PVmMeta?.alias || [],
    redirect: indexYt43e36PVmMeta?.redirect,
    component: () => import("/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/index.vue").then(m => m.default || m)
  },
  {
    name: transformation_45of_45an_45edav_45into_45a_45reitx202jh6zfHMeta?.name ?? "portfolio-news-transformation-of-an-edav-into-a-reit",
    path: transformation_45of_45an_45edav_45into_45a_45reitx202jh6zfHMeta?.path ?? "/portfolio-news/transformation-of-an-edav-into-a-reit",
    meta: transformation_45of_45an_45edav_45into_45a_45reitx202jh6zfHMeta || {},
    alias: transformation_45of_45an_45edav_45into_45a_45reitx202jh6zfHMeta?.alias || [],
    redirect: transformation_45of_45an_45edav_45into_45a_45reitx202jh6zfHMeta?.redirect,
    component: () => import("/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/transformation-of-an-edav-into-a-reit.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45partnersvycwePvHVoMeta?.name ?? "portfolio-partners",
    path: portfolio_45partnersvycwePvHVoMeta?.path ?? "/portfolio-partners",
    meta: portfolio_45partnersvycwePvHVoMeta || {},
    alias: portfolio_45partnersvycwePvHVoMeta?.alias || [],
    redirect: portfolio_45partnersvycwePvHVoMeta?.redirect,
    component: () => import("/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-partners.vue").then(m => m.default || m)
  },
  {
    name: privacymQAqqqyv1EMeta?.name ?? "privacy",
    path: privacymQAqqqyv1EMeta?.path ?? "/privacy",
    meta: privacymQAqqqyv1EMeta || {},
    alias: privacymQAqqqyv1EMeta?.alias || [],
    redirect: privacymQAqqqyv1EMeta?.redirect,
    component: () => import("/codebuild/output/src1957375341/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/privacy.vue").then(m => m.default || m)
  }
]